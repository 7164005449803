import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const AddFile = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state

    const [data, setData] = useState({
        sortOrder: editData?.sortOrder || '',
        name: editData?.name || '',
        file: editData?.file || '',
        isActive: editData?.isActive || false,
        category: editData.category?._id || location.state
    });
    const [errors, setErrors] = useState({
        sortOrder: '',
        file: '',
        name: ''
    });

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'file') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === "isActive") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }

        if (!data.name.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name  is required'
            }));
            hasErrors = true;
        }

        if (!data.file) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                file: 'file is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("sortOrder", data.sortOrder);
            if (data.file instanceof File) {
                formData.append("file", data.file);
            }
            formData.append("isActive", data.isActive);
            formData.append("name", data.name);
            formData.append("category", data.category)
            const response = await axiosInstance.post(`categoryFile/createCategoryFile`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/category");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("sortOrder", data.sortOrder);
            if (data.file instanceof File) {
                formData.append("file", data.file);
            }
            formData.append("name", data.name);
            formData.append("isActive", data.isActive);
            formData.append("category", data.category)
            const response = await axiosInstance.put(`categoryFile/updateCategoryFile/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/category");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Add File </h3>
                </div>
                <div className="row grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            file
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="file"
                                                name="file"
                                                onChange={handleChange}
                                            />
                                            {errors.file && <p className="text-danger">{errors.file}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label"></label>
                                                <div className="col-sm-9">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/category"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.file && <div className="border w-100 h-75 text-center">
                                <h6 className="mt-3">file Preview</h6>
                                <br />
                                {data.file instanceof File ? (
                                    <Link to={URL.createObjectURL(data.file)} target="_blank">
                                        <img
                                            src="images/icon/pdf.png"
                                            alt="pdf_preview"
                                            className="ms-3 me-3 mb-3"
                                            width={60}
                                        />
                                    </Link>
                                ) : (
                                    <div>
                                        <Link to={ImageLink(data.file)} target="_blank">
                                            <img
                                                src="images/icon/pdf.png"
                                                alt="pdf_preview"
                                                className="ms-3 me-3 mb-3"
                                                width={60}
                                            />
                                        </Link>
                                    </div>

                                )}

                            </div>}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddFile