import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ImageLink } from '../constants';

const AddSubmenu = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const parentId = location.pathname.split("/")[2];
    const [selectedOptions, setSelectedOptions] = useState(editData?.brand?.map((item) => ({ value: item._id, label: item.title })) || [])
    const [selectedCollection, setSelectedCollection] = useState(editData?.collection?.map((item) => ({ value: item._id, label: item.title })) || [])
    const [data, setData] = useState({
        parentId: parentId || '',
        menuName: editData?.menuName || '',
        title: editData?.title || '',
        subTitle: editData?.subTitle || '',
        sortOrder: editData?.sortOrder || '',
        menuUrl: editData?.menuUrl || '',
        menuType: editData?.menuType || '',
        category: editData?.category?._id || '',
        cms: editData?.cms?._id || '',
        desktopImg: editData?.desktopImg || '',
        mobileImg: editData?.mobileImg || '',
        isActive: editData?.isActive || false,
        showInHeader: editData?.showInHeader || false,
        showInFooter: editData?.showInFooter || false
    });

    const [errors, setErrors] = useState({
        sortOrder: '',
        menuName: '',
        menuUrl: '',
        title: '',
        subtitle: '',
        desktopImg: '',
    });

    const [pageData, setPageData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`menu/getmenu`,);
            const { data } = response.data;
            setPageData(data);
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [brandData, setBrandData] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [cmsData, setCmsData] = useState([]);

    const fetchBrandData = async () => {
        try {
            const response = await axiosInstance.get(`brand/getWithoutPaginationBrand`,);
            const { data } = response.data;
            setBrandData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCollectionData = async () => {
        try {
            const response = await axiosInstance.get(`collection/getWithoutPaginationCollection`);
            const { data } = response.data;
            setCollectionData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCategoryData = async () => {
        try {
            const response = await axiosInstance.get(`category/getCategory`);
            const { data } = response.data;
            setCategoryData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCmsData = async () => {
        try {
            const response = await axiosInstance.get(`page/getpages`);
            const { data } = response.data;
            setCmsData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };


    useEffect(() => {
        fetchBrandData();
        fetchCollectionData();
        fetchCategoryData();
        fetchCmsData();
    }, []);

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'desktopImg' || name === 'mobileImg') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === "isActive" || name === "showInFooter" || name === "showInHeader") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }
        if (!data.menuName) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menuName: 'Menu name is required'
            }));
            hasErrors = true;
        }
        if (!data.title) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }
        if (!data.subTitle) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                subtitle: 'Sub title is required'
            }));
            hasErrors = true;
        }
        if (!data.menuUrl) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menuUrl: 'Menu url is required'
            }));
            hasErrors = true;
        }

        if (!data.desktopImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                desktopImg: 'Desktop img is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId)
            }
            formData.append("menuName", data.menuName);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("menuType", data.menuType);
            if (data.category) {
                formData.append("category", data.category);
            }
            formData.append("menuUrl", data.menuUrl);
            formData.append("sortOrder", data.sortOrder);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('brand[]', selectedOptions[i].value);
            }
            for (let i = 0; i < selectedCollection?.length; i++) {
                formData.append('collection[]', selectedCollection[i].value);
            }
            if (data.cms) {
                formData.append("cms", data.cms)
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            formData.append("isActive", data.isActive);
            formData.append("showInFooter", data.showInFooter);
            formData.append("showInHeader", data.showInHeader);
            const response = await axiosInstance.post(`menu/createMenu`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/sub-menu");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId)
            }
            formData.append("menuName", data.menuName);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("menuType", data.menuType);
            if (data.category) {
                formData.append("category", data.category);
            }
            formData.append("menuUrl", data.menuUrl);
            formData.append("sortOrder", data.sortOrder);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('brand[]', selectedOptions[i].value);
            }
            for (let i = 0; i < selectedCollection?.length; i++) {
                formData.append('collection[]', selectedCollection[i].value);
            }
            if (data.cms) {
                formData.append("cms", data.cms)
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            formData.append("isActive", data.isActive);
            formData.append("showInFooter", data.showInFooter);
            formData.append("showInHeader", data.showInHeader);
            const response = await axiosInstance.put(`menu/updateMenu/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/menu");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add Menu </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Base Menu
                                        </label>
                                        <div className="col-sm-9">
                                            <select type="text"
                                                className="form-control form-control-sm"
                                                id="parentId"
                                                name="parentId"
                                                placeholder="Select page id"
                                                onChange={handleChange}
                                                value={data.parentId}
                                            >
                                                <option value=''>--- Root content ---</option>
                                                {
                                                    pageData.map((item, id) => {
                                                        return (
                                                            <option key={id} value={item._id}>{item.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Menu Type
                                        </label>
                                        <div className="col-sm-9">
                                            <select type="text"
                                                className="form-control form-control-sm"
                                                id="menuType"
                                                name="menuType"
                                                placeholder="Select menu type"
                                                onChange={handleChange}
                                                value={data.menuType}
                                            >
                                                <option value="">--- Select menu type ---</option>
                                                <option value="CMS">CMS</option>
                                                <option value="Brand">Brand</option>
                                                <option value="Collection">Collection</option>
                                                <option value="Category">Category</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    {data.menuType === 'Brand' &&
                                        <div className="form-group row">
                                            <label
                                                htmlFor="exampleInputUsername2"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Brand
                                            </label>
                                            <div className="col-sm-9">
                                                <Select
                                                    id="brand"
                                                    name="brand"
                                                    placeholder="Select brand"
                                                    value={selectedOptions}
                                                    onChange={setSelectedOptions}
                                                    options={brandData?.map((item) => ({ value: item._id, label: item.title }))}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                    }

                                    {data.menuType === 'CMS' &&
                                        <div className="form-group row">
                                            <label
                                                htmlFor="exampleInputUsername2"
                                                className="col-sm-3 col-form-label"
                                            >
                                                CMS
                                            </label>
                                            <div className="col-sm-9">
                                                <select type="text"
                                                    className="form-control form-control-sm"
                                                    id="cms"
                                                    name="cms"
                                                    placeholder="Select cms"
                                                    onChange={handleChange}
                                                    value={data.cms}
                                                >
                                                    <option value='-'>--- Select cms---</option>
                                                    {
                                                        cmsData.map((item, id) => {
                                                            return (
                                                                <option key={id} value={item._id}>{item.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {data.menuType === 'Category' &&
                                        <div className="form-group row">
                                            <label
                                                htmlFor="exampleInputUsername2"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Category
                                            </label>
                                            <div className="col-sm-9">
                                                <select type="text"
                                                    className="form-control form-control-sm"
                                                    id="category"
                                                    name="category"
                                                    placeholder="Select category"
                                                    onChange={handleChange}
                                                    value={data.category}
                                                >
                                                    <option value="">--- Select category ---</option>
                                                    {
                                                        categoryData.map((item, id) => {
                                                            return (
                                                                <option key={id} value={item._id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {data.menuType === 'Collection' &&
                                        <div className="form-group row">
                                            <label
                                                htmlFor="exampleInputUsername2"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Collection
                                            </label>
                                            <div className="col-sm-9">
                                                <Select
                                                    id="collection"
                                                    name="collection"
                                                    placeholder="Select collection"
                                                    value={selectedCollection}
                                                    onChange={setSelectedCollection}
                                                    options={collectionData?.map((item) => ({ value: item._id, label: item.title }))}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Menu Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="menuName"
                                                name="menuName"
                                                placeholder="Enter menu name"
                                                value={data.menuName}
                                                onChange={handleChange}
                                            />
                                            {errors.menuName && <p className="text-danger">{errors.menuName}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Menu Url
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="menuUrl"
                                                name="menuUrl"
                                                placeholder="Enter menu url"
                                                value={data.menuUrl}
                                                onChange={handleChange}
                                            />
                                            {errors.menuUrl && <p className="text-danger">{errors.menuUrl}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="subTitle"
                                                name="subTitle"
                                                placeholder="Enter sub title"
                                                value={data.subTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.subTitle && <p className="text-danger">{errors.subTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Desktop Img
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="desktopImg"
                                                name="desktopImg"
                                                placeholder="Enter slider desktop img"
                                                onChange={handleChange}
                                            />
                                            {errors.desktopImg && <p className="text-danger">{errors.desktopImg}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Mobile Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="mobileImg"
                                                name="mobileImg"
                                                placeholder="Enter slider mobile img"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        > Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInHeader"
                                            className="col-sm-3 "
                                        > Show In Header
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInHeader'
                                                    id="showInHeader"
                                                    checked={data.showInHeader}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInHeader">{data.showInHeader === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInFooter"
                                            className="col-sm-3 "
                                        > Show In Footer
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInFooter'
                                                    id="showInFooter"
                                                    checked={data.showInFooter}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInFooter">{data.showInFooter === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/sub-menu"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.desktopImg &&
                                <div className="border w-100 h-75 text-center">
                                    <h6 className="mt-3">Desktop Preview</h6>
                                    <br />
                                    {data.desktopImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.desktopImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.desktopImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSubmenu