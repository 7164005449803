import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'


const AddCounter = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state
  const [data, setData] = useState({
    sortOrder: editData?.sortOrder || '',
    title: editData?.title || '',
    number: editData?.number || '',
    icon: editData?.icon || '',
    image: editData?.image || ''
  });

  const [errors, setErrors] = useState({
    sortOrder: '',
    title: '',
    number: '',
    image: '',
    icon: ''
  });

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    if (name === 'image') {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.sortOrder.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sortOrder: 'Sort Order is required'
      }));
      hasErrors = true;
    }
    if (!data.title) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: 'Title is required'
      }));
      hasErrors = true;
    }
    if (!data.number) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        number: 'Number is required'
      }));
      hasErrors = true;
    }

    if (!data.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'Image is required'
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("sortOrder", data.sortOrder);
      formData.append("number", data.number);
      formData.append("icon", data.icon);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }

      const response = await axiosInstance.post(`counter/createCounter`, formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/counter");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {

      toast.error('Something went wrong. Please try again!!');
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("sortOrder", data.sortOrder);
      formData.append("number", data.number);
      formData.append("icon", data.icon);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
      const response = await axiosInstance.put(`counter/updateCounter/${editData?._id}`, formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/counter");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {

      toast.error('Something went wrong. Please try again!!');
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">Add Counter </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-8  ">
            <div className="card">
              <div className="card-body">
                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Sort Order
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="sortOrder"
                        name="sortOrder"
                        placeholder="Enter sort order"
                        value={data.sortOrder}
                        onChange={handleChange}
                      />
                      {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="title"
                        name="title"
                        placeholder="Enter title"
                        value={data.title}
                        onChange={handleChange}
                      />
                      {errors.title && <p className="text-danger">{errors.title}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="number"
                        name="number"
                        placeholder="Enter number"
                        value={data.number}
                        onChange={handleChange}
                      />
                      {errors.number && <p className="text-danger">{errors.number}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Icon
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="icon"
                        name="icon"
                        placeholder="Enter icon"
                        value={data.icon}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Image
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="image"
                        name="image"
                        onChange={handleChange}
                      />
                      {errors.image && <p className="text-danger">{errors.image}</p>}
                    </div>
                  </div>
                  <div className='text-center'>
                    <Link to="/counter"><button className="btn btn-secondary mr-2">
                      Back </button></Link>
                    <button type="submit" className="btn btn-success">
                      {editData ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 stretch-card">
            <div className="card">
              <div className="border w-100 h-75 text-center">
                <h6 className="mt-3">Image Preview</h6>
                <br />
                {data.image instanceof File ? (
                  <img src={URL.createObjectURL(data.image)} alt="image_preview" className="w-50 mb-3" />
                ) : (
                  <img src={ImageLink(data.image)} alt="image_preview" className="w-50 mb-3" />
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCounter;