
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInterceptor';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const EmailSetting = () => {
    const [settingData, setSettingData] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: '',
        host: '',
        port: '',
        toEmail: '',
        bccEmail: '',
        ccEmail: '',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`emailsetting/getEmailSetting`);
            const { data } = response.data;
            setSettingData(data);
            setData({ ...data });
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const newData = { ...data };
        if (newData._id) {
            delete newData._id;
            delete newData.createdAt;
            delete newData.updatedAt;
            delete newData.__v;
        }
        try {
            const response = await axiosInstance.put(`emailsetting/updateEmailSetting`, newData);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Email Settings </h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3" onSubmit={handleUpdate}>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={data.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Password
                                    </label>
                                    <div className="col-sm-9 input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control form-control-sm"
                                            id="password"
                                            name="password"
                                            placeholder="Enter password"
                                            value={data.password}
                                            onChange={handleChange}
                                        />
                                        <span className="input-group-text" onClick={togglePasswordVisibility} style={{ height: "41px" }}>
                                            {showPassword ? <FaEye className='fs-2' /> : <FaEyeSlash className='fs-2' />}
                                        </span>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Host
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="host"
                                            name="host"
                                            placeholder="Enter host"
                                            value={data.host}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Port
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            id="port"
                                            name="port"
                                            placeholder="Enter port"
                                            value={data.port}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        To Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="toEmail"
                                            name="toEmail"
                                            placeholder="Enter to email"
                                            value={data.toEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Bcc Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="bccEmail"
                                            name="bccEmail"
                                            placeholder="Enter bcc email"
                                            value={data.bccEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Cc Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="ccEmail"
                                            name="ccEmail"
                                            placeholder="Enter cc email"
                                            value={data.ccEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <Link to="/dashboard"><button className="btn btn-secondary mr-2">Back</button></Link>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailSetting;