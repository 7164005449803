import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'
import CodeEditor from './CodeEditor'

const AddContent = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state

    const [data, setData] = useState({
        position: editData?.position || '',
        sortOrder: editData?.sortOrder || '',
        title: editData?.title || '',
        subTitle: editData?.subTitle || '',
        description: editData?.description || '',
        desktopImg: editData?.box_image || '',
        mobileImg: editData?.cover_image || '',
        isActive: editData?.isActive || false,
        showInHome: editData?.showInHome || false,
    });
    const [errors, setErrors] = useState({
        sortOrder: '',
        title: '',
        subTitle: '',
        description: '',

    });

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'desktopImg' || name === 'mobileImg') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === 'isActive' || name === 'showInHome') {
            setData({ ...data, [name]: checked });
        }
        else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.title) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }

        if (!data.subTitle) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                subTitle: 'Sub title is required'
            }));
            hasErrors = true;
        }
        if (!data.description) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                description: 'Description is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            if (data.position) {
                formData.append("position", data.position);
            }
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("description", data.description);
            formData.append("sortOrder", data.sortOrder);
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);
            const response = await axiosInstance.post(`content/createContent`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/content");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (data.position) {
                formData.append("position", data.position);
            }
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("description", data.description);
            formData.append("sortOrder", data.sortOrder);
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);
            const response = await axiosInstance.put(`content/updateContent/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/content");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mx-4 mt-5">
                    <h3 className="page-title">Add Content </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Position
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="position"
                                                name="position"
                                                placeholder="Enter position"
                                                value={data.position}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="subTitle"
                                                name="subTitle"
                                                placeholder="Enter sub title"
                                                value={data.subTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.subTitle && <p className="text-danger">{errors.subTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Description
                                        </label>
                                        <div className="col-sm-9">
                                            <CodeEditor
                                                value={data?.description}
                                                placeholder='Enter description ...'
                                                onChange={(value) => handleChange({ target: { name: 'description', value } })}
                                            />
                                            {errors.description && !data?.description?.trim() && (
                                                <div className="text-danger">Description is required</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Desktop Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="desktopImg"
                                                name="desktopImg"
                                                placeholder="Enter desktop img"
                                                onChange={handleChange}
                                            />

                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Mobile Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="mobileImg"
                                                name="mobileImg"
                                                placeholder="Enter mobile img"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="showInHome" className="col-sm-3 ">
                                            {" "}
                                            Show In Home
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="showInHome"
                                                    id="showInHome"
                                                    checked={data.showInHome}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <label
                                                    className="custom-control-label "
                                                    htmlFor="showInHome"
                                                >
                                                    {data.showInHome === true ? "Active" : "Deactive"}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/content"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.desktopImg && <div className="border w-100 h-25 text-center">
                                <h6 className="mt-3">Desktop Image Preview</h6>
                                <br />
                                {data.desktopImg instanceof File ? (
                                    <img src={URL.createObjectURL(data.desktopImg)} alt="desktopImg" className="w-50" />
                                ) : (
                                    <img src={ImageLink(data.desktopImg)} alt="desktopImg" className="w-50" />
                                )}
                            </div>}

                            {data.mobileImg && <div className="border w-100 h-25 text-center">
                                <h6 className="mt-3">Mobile Image Preview</h6>
                                <br />
                                {data.mobileImg instanceof File ? (
                                    <img src={URL.createObjectURL(data.mobileImg)} alt="mobile_Img" className="w-50" />
                                ) : (
                                    <img src={ImageLink(data.mobileImg)} alt="mobile_Img" className="w-50" />
                                )}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddContent;