import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "./dashboard/Dashboard";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import Login from './authentication/Login';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Forgotpassword from './authentication/Forgotpassword';
import Slider from './masterpages/Slider';
import AddSlider from './masterpages/AddSlider';
import Product from './masterpages/Product';
import AddProduct from './masterpages/AddProduct';
import Brand from './masterpages/Brand';
import AddBrand from './masterpages/AddBrand';
import AddCounter from './masterpages/AddCounter';
import Counter from './masterpages/Counter';
import Menu from './masterpages/Menu';
import AddMenu from './masterpages/AddMenu';
import Setting from './setting/Setting';
import ResetPassword from './authentication/ResetPassword';
import ChangePasssword from './authentication/ChangePasssword';
import EmailSetting from './setting/EmailSetting';
import Contact from './masterpages/Contact';
import Profile from './masterpages/Profile';
import Page from './masterpages/Page';
import AddPage from './masterpages/AddPage';
import AddContent from './masterpages/AddContent';
import Content from './masterpages/Content';
import Contactview from './masterpages/Contactview';
import SocialMedia from './masterpages/SocialMedia';
import AddSocialMedia from './masterpages/AddSocialMedia';
import Future from './masterpages/Future';
import AddFuture from './masterpages/AddFuture';
import Testimonial from './masterpages/Testimonial';
import AddTestimonial from './masterpages/AddTestimonial';
import Collection from './masterpages/Collection';
import AddCollection from './masterpages/AddCollection';
import Client from './masterpages/Client';
import AddClient from './masterpages/AddClient';
import Branch from './masterpages/Branch';
import AddBranch from './masterpages/AddBranch';
import CoreValue from './masterpages/CoreValue';
import AddCoreValue from './masterpages/AddCoreValue';
import Gallery from './masterpages/Gallery';
import AddGallery from './masterpages/AddGallery';
import Category from './masterpages/Category';
import AddCategory from './masterpages/AddCategory';
import FileData from './masterpages/FileData';
import AddFile from './masterpages/AddFile';
import Subcategory from './masterpages/Subcategory';
import ChildCategory from './masterpages/ChildCategory';
import AddSubcategory from './masterpages/AddSubcategory';
import AddChildCategory from './masterpages/AddChildCategory';
import EmailTemplate from './setting/EmailTemplate';
import Sitemap from './masterpages/Sitemap';
import Submenu from './masterpages/Submenu';
import AddSubmenu from './masterpages/AddSubmenu';
import Scroll from './Layout/Scroll';

function RequireAuth({ children }) {
  let accessToken = localStorage.getItem("ashapuriAdminToken");
  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return children;
}

function App() {
  let accessToken = localStorage.getItem("ashapuriAdminToken");
  return (
    <Router>
      <Routes>
        {!accessToken && <Route path="/" element={<Login />} />}
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Scroll />
              <Header />
              <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                  <Sidebar />
                  <div className="main-panel">
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/slider" element={<Slider />} />
                      <Route path="/add-slider" element={<AddSlider />} />
                      <Route path="/product" element={<Product />} />
                      <Route path="/add-product" element={<AddProduct />} />
                      <Route path="/brand" element={<Brand />} />
                      <Route path="/add-brand" element={<AddBrand />} />
                      <Route path="/counter" element={<Counter />} />
                      <Route path="/add-counter" element={<AddCounter />} />
                      <Route path="/menu" element={<Menu />} />
                      <Route path="/add-menu" element={<AddMenu />} />
                      <Route path="/menu/:id" element={<Submenu />} />
                      <Route path="/add-sub-menu/:parentId" element={<AddSubmenu />} />
                      <Route path="/settings" element={<Setting />} />
                      <Route path="/change-password" element={<ChangePasssword />} />
                      <Route path="/email-configuration" element={<EmailSetting />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/page" element={<Page />} />
                      <Route path="/add-page" element={<AddPage />} />
                      <Route path="/content" element={<Content />} />
                      <Route path="/add-content" element={<AddContent />} />
                      <Route path="/contact-view" element={<Contactview />} />
                      <Route path="/social-media" element={<SocialMedia />} />
                      <Route path="/add-social-media" element={<AddSocialMedia />} />
                      <Route path="/future" element={<Future />} />
                      <Route path="/add-future" element={<AddFuture />} />
                      <Route path="/testimonial" element={<Testimonial />} />
                      <Route path="/add-testimonial" element={<AddTestimonial />} />
                      <Route path="/collection" element={<Collection />} />
                      <Route path="/add-collection" element={<AddCollection />} />
                      <Route path="/client" element={<Client />} />
                      <Route path="/add-client" element={<AddClient />} />
                      <Route path="/branch" element={<Branch />} />
                      <Route path="/add-branch" element={<AddBranch />} />
                      <Route path="/core-value" element={<CoreValue />} />
                      <Route path="/add-core-value" element={<AddCoreValue />} />
                      <Route path="/gallery" element={<Gallery />} />
                      <Route path="/add-gallery" element={<AddGallery />} />
                      <Route path="/category" element={<Category />} />
                      <Route path="/add-category" element={<AddCategory />} />
                      <Route path="/file" element={<FileData />} />
                      <Route path="/add-file" element={<AddFile />} />
                      <Route path="/sub-category" element={<Subcategory />} />
                      <Route path="/child-category" element={<ChildCategory />} />
                      <Route path="/add-child-category" element={<AddChildCategory />} />
                      <Route path="/add-sub-category" element={<AddSubcategory />} />
                      <Route path="/email-template" element={<EmailTemplate />} />
                      <Route path="/sitemap" element={<Sitemap />} />
                    </Routes>
                    <Footer />
                  </div>
                </div>
              </div>
            </RequireAuth>
          }
        />
      </Routes>
      <ToastContainer autoClose={1000} />
    </Router>
  );
}

export default App;


