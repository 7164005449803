import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
                        Copyright ©{new Date().getFullYear()} Ashapuri Gold Industries Pvt. Ltd | Website Designed By:
                        <Link
                            target="_blank"
                            className="text-gray hover-white"
                            to="https://www.setblue.com/"
                            title="+91 98985 98984"
                        >
                            Setblue
                        </Link>

                    </span>

                </div>
            </footer>
        </>
    )
}

export default Footer