import React, { useState } from 'react'
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
    const location = useLocation().pathname;
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (itemName) => {
        setSelectedItem(itemName === selectedItem ? null : itemName);
    };
    return (
        <>
            <nav className={`sidebar sidebar-offcanvas ${location.pathname === "/" || location.pathname === "/forgot-password" ? "d-none" : ""}`} id="sidebar">
                <ul className="nav">

                    <li className={`nav-item nav-profile ${location === "/dashboard" && 'active'}`}>
                        <Link className="nav-link" to="/dashboard">
                            <span className="menu-title">Dashboard</span>
                            <i className="icon-screen-desktop menu-icon " />

                        </Link>
                    </li>

                    <li className={`nav-item  ${location === "/contact" && 'active'}`}>
                        <Link className="nav-link" to="/contact">
                            <span className="menu-title">Contact Details</span>
                            <i className="icon-user menu-icon" />
                        </Link>
                    </li>
                    <li className={`nav-item  ${location === "/sitemap" && 'active'}`}>
                        <Link className="nav-link" to="/sitemap">
                            <span className="menu-title">Sitemap</span>
                            <i className="icon-settings menu-icon" />
                        </Link>
                    </li>
                    <li className={`nav-item  ${location === "/settings" && 'active'}`}>
                        <Link className="nav-link" to="/settings">
                            <span className="menu-title">Settings</span>
                            <i className="icon-settings menu-icon" />
                        </Link>
                    </li>
                    <li className={`nav-item  ${location === "/email-configuration" && 'active'}`}>
                        <Link className="nav-link" to="/email-configuration">
                            <span className="menu-title">Email Configuration </span>
                            <i className="icon-globe menu-icon" />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-toggle="collapse"
                            href="#ui-basic"
                            aria-expanded="false"
                            aria-controls="ui-basic"
                        >
                            <span className="menu-title">Master Tables</span>
                            <i className="icon-layers menu-icon" />
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/menu" onClick={() => handleItemClick('Menu')}>
                                        {selectedItem === 'Menu' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}
                                        Menu
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/slider" onClick={() => handleItemClick('Slider')}>
                                        {selectedItem === 'Slider' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Slider
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/product" onClick={() => handleItemClick('Product')}>
                                        {selectedItem === 'Product' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Product
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/category" onClick={() => handleItemClick('Category')}>
                                        {selectedItem === 'Category' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Category
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/brand" onClick={() => handleItemClick('Brand')}>
                                        {selectedItem === 'Brand' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Brand
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/collection" onClick={() => handleItemClick('Collection')}>
                                        {selectedItem === 'Collection' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}  Collection
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/counter" onClick={() => handleItemClick('Counter')}>
                                        {selectedItem === 'Counter' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Counter
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/core-value" onClick={() => handleItemClick('CoreValue')}>
                                        {selectedItem === 'CoreValue' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} CoreValue
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/future" onClick={() => handleItemClick('Future')}>
                                        {selectedItem === 'Future' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Future
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/client" onClick={() => handleItemClick('Client')}>
                                        {selectedItem === 'Client' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Client
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/branch" onClick={() => handleItemClick('Branch')}>
                                        {selectedItem === 'Branch' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Branch
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/gallery" onClick={() => handleItemClick('Gallery')}>
                                        {selectedItem === 'Gallery' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Gallery
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/testimonial" onClick={() => handleItemClick('Testimonial')}>
                                        {selectedItem === 'Testimonial' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Testimonial
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/page" onClick={() => handleItemClick('Page')}>
                                        {selectedItem === 'Page' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Page
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/content" onClick={() => handleItemClick('Content')}>
                                        {selectedItem === 'Content' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Content
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/social-media" onClick={() => handleItemClick('Social Media')}>
                                        {selectedItem === 'Social Media' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Social Media
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/sitemap" onClick={() => handleItemClick('Sitemap')}>
                                        {selectedItem === 'Sitemap' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Sitemap
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/email-template" onClick={() => handleItemClick('Email Template')}>
                                        {selectedItem === 'Email Template' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Email Template
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>


        </>
    )
}

export default Sidebar