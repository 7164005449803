import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Contactview = () => {
    const location = useLocation();
    const viewData = location.state;

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Contact Details </h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-8  ">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3">
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Name
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="name"
                                            name="name"
                                            placeholder="Enter name"
                                            value={viewData.name}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={viewData.email}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Subject
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="subject"
                                            name="subject"
                                            placeholder="Enter subject"
                                            value={viewData.subject}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Message
                                    </label>
                                    <div className="col-sm-9">
                                        <textarea
                                            className="form-control form-control-sm"
                                            id="message"
                                            name="message"
                                            placeholder="Enter message"
                                            value={viewData.message}
                                            disabled
                                            style={{ height: '150px' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Phone Number
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Enter phone number"
                                            value={viewData.phoneNumber}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <Link to="/contact"><button className="btn btn-secondary mr-2">
                                        Back </button></Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactview;
