import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";
import CodeEditor from "../masterpages/CodeEditor";

const EmailTemplate = () => {
    const [settingData, setSettingData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 10;
    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState({   
        name: '',  
        subject: '',
        description: '',        
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.post(`/emailTemplate/getAllEmailTemplate`, {
                perPage: perPage,
                pageNo: currentPage,
            });          
            const { data } = response.data;
            setSettingData(data);
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };


    const handleSelectChange = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedItem = selectedIndex > 0 ? settingData[selectedIndex - 1] : null;
        setSelectedItem(selectedItem);
        setData({ ...data, name: selectedItem?.name, subject: selectedItem?.subject, description: selectedItem?.description });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();      
        try {
            const response = await axiosInstance.put(`/emailTemplate/updateEmailTemplate/${selectedItem._id}`, data);
            if (response.status === 200) {
                toast.success(response.data.message);               
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Email Template </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={handleUpdate}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Email Template Name
                                        </label>
                                        <div className="col-sm-9">
                                            <select
                                                className="form-control form-control-sm"
                                                id="parentCategory"
                                                name="parentCategory"
                                                onChange={handleSelectChange}
                                            >
                                                <option value='-'>--- Root content ---</option>
                                                {
                                                    settingData.map((item, id) => (
                                                        <option key={id} value={item._id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Subject
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="subject"
                                                name="subject"
                                                placeholder="Enter subject"
                                                value={data.subject}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Description
                                        </label>
                                        <div className="col-sm-9">
                                            <CodeEditor
                                                value={data.description} 
                                                placeholder='Enter description ...'
                                                onChange={(value) => handleChange({ target: { name: 'description', value } })}
                                            />
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/dashboard"><button className="btn btn-secondary mr-2">Back</button></Link>
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailTemplate;
