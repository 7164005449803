import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const AddCollection = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const [data, setData] = useState({
        name: editData?.name || '',
        sortOrder: editData?.sortOrder || '',
        title: editData?.title || '',
        subTitle: editData?.subTitle || '',
        desktopImg: editData?.desktopImg || '',
        banner_image: editData?.banner_image || '',
        metaTitle: editData?.metaTitle || '',
        metaKeyword: editData?.metaKeyword || '',
        metaDescription: editData?.metaDescription || '',
        isActive: editData?.isActive || false,
        showInHome: editData?.showInHome || false,
    });
    const [errors, setErrors] = useState({
        name: '',
        title: '',
        subTitle: '',
        sortOrder: '',
        desktopImg: '',
        banner_image: '',
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
    });

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'banner_image' || name === 'desktopImg') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === "isActive" || name === 'showInHome') {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.name.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required'
            }));
            hasErrors = true;
        }

        if (!data.title.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }

        if (!data.subTitle.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                subTitle: 'Sub title is required'
            }));
            hasErrors = true;
        }

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }

        if (!data.desktopImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                desktopImg: 'Desktop img is required'
            }));
            hasErrors = true;
        }
        if (!data.metaTitle.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaTitle: 'Meta title is required'
            }));
            hasErrors = true;
        }
        if (!data.metaKeyword.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaKeyword: 'Meta keyword is required'
            }));
            hasErrors = true;
        }
        if (!data.metaDescription.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaDescription: 'Meta description is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            if (data.banner_image instanceof File) {
                formData.append("banner_image", data.banner_image);
            }
            if (data.mobilebanner_image instanceof File) {
                formData.append("mobilebanner_image", data.mobilebanner_image);
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);

            const response = await axiosInstance.post(`collection/createCollection`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/collection");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            if (data.banner_image instanceof File) {
                formData.append("banner_image", data.banner_image);
            }

            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }

            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);

            const response = await axiosInstance.put(`collection/updateCollection/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/collection");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Add Collection </h3>
                </div>
                <div className="row grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="subTitle"
                                                name="subTitle"
                                                placeholder="Enter sub title"
                                                value={data.subTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.subTitle && <p className="text-danger">{errors.subTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Desktop Image
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="desktopImg"
                                                name="desktopImg"
                                                placeholder="Enter desktop img"
                                                onChange={handleChange}
                                            />
                                            {errors.desktopImg && <p className="text-danger">{errors.desktopImg}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Banner Image
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="banner_image"
                                                name="banner_image"
                                                placeholder="Enter banner img"
                                                onChange={handleChange}
                                            />
                                            {errors.banner_image && <p className="text-danger">{errors.banner_image}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.metaTitle && <p className="text-danger">{errors.metaTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Keyword
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                            {errors.metaKeyword && <p className="text-danger">{errors.metaKeyword}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                            {errors.metaDescription && <p className="text-danger">{errors.metaDescription}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInHome"
                                            className="col-sm-3 "
                                        >Show In Home
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInHome'
                                                    id="showInHome"
                                                    checked={data.showInHome}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInHome">{data.showInHome === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/collection"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.banner_image &&
                                <div className="border w-100 h-75 text-center">
                                    <h6 className="mt-3">Banner Preview</h6>
                                    <br />
                                    {data.banner_image instanceof File ? (
                                        <img src={URL.createObjectURL(data.banner_image)} alt="banner_image" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.banner_image)} alt="banner_image" className="w-50 mb-3" />
                                    )}
                                </div>
                            }
                            {data.desktopImg &&
                                <div className="border w-100 h-80 text-center mb-4">
                                    <h6 className="mt-3">Desktop Preview</h6>
                                    <br />
                                    {data.desktopImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.desktopImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.desktopImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCollection;