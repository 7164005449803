import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const AddBrand = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;

    const [data, setData] = useState({
        name: editData?.name || '',
        sortOrder: editData?.sortOrder || '',
        title: editData?.title || '',
        subTitle: editData?.subTitle || '',
        slugname: editData?.slug || '',
        metaTitle: editData?.metaTitle || '',
        metaKeyword: editData?.metaKeyword || '',
        metaDescription: editData?.metaDescription || '',
        box_image: editData?.box_image || '',
        cover_image: editData?.cover_image || '',
        logo_image: editData?.logo_image || '',
        home_image: editData?.home_image || '',
        showInHome: editData?.showInHome || false,
        showInBrandFamily: editData?.showInBrandFamily || false,
        isActive: editData?.isActive || false,
    });
    const [errors, setErrors] = useState({
        name: '',
        sortOrder: '',
        title: '',
        subTitle: '',
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
        box_image: '',
        cover_image: '',
        logo_image: '',
        home_image: ''
    });

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'box_image' || name === 'cover_image' || name === 'logo_image' || name === 'home_image') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === 'isActive' || name === 'showInHome' || name === 'showInBrandFamily') {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.name.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required'
            }));
            hasErrors = true;
        }

        if (!data.title) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }

        if (!data.subTitle) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                subTitle: 'Sub title is required'
            }));
            hasErrors = true;
        }

        if (!data.metaTitle) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaTitle: 'Meta title is required'
            }));
            hasErrors = true;
        }

        if (!data.metaKeyword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaKeyword: 'Meta keyword is required'
            }));
            hasErrors = true;
        }
        if (!data.metaDescription) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaDescription: 'Meta description is required'
            }));
            hasErrors = true;
        }

        if (!data.box_image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                box_image: 'Brand image is required'
            }));
            hasErrors = true;
        }

        if (!data.cover_image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                cover_image: 'Menu image is required'
            }));
            hasErrors = true;
        }

        if (!data.logo_image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                logo_image: 'Logo image is required'
            }));
            hasErrors = true;
        }
        if (!data.home_image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                home_image: 'Home image is required'
            }));
            hasErrors = true;
        }
        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("slugname", data.slugname);
            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);
            formData.append("showInBrandFamily", data.showInBrandFamily);
            if (data.box_image instanceof File) {
                formData.append("box_image", data.box_image);
            }
            if (data.cover_image instanceof File) {
                formData.append("cover_image", data.cover_image);
            }
            if (data.logo_image instanceof File) {
                formData.append("logo_image", data.logo_image);
            }
            if (data.home_image instanceof File) {
                formData.append("home_image", data.home_image);
            }
            const response = await axiosInstance.post(`brand/createBrand`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/brand");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("title", data.title);
            formData.append("subTitle", data.subTitle);
            formData.append("slugname", data.slugname);
            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            formData.append("isActive", data.isActive);
            formData.append("showInHome", data.showInHome);
            formData.append("showInBrandFamily", data.showInBrandFamily);
            if (data.box_image instanceof File) {
                formData.append("box_image", data.box_image);
            }
            if (data.cover_image instanceof File) {
                formData.append("cover_image", data.cover_image);
            }
            if (data.logo_image instanceof File) {
                formData.append("logo_image", data.logo_image);
            }
            if (data.home_image instanceof File) {
                formData.append("home_image", data.home_image);
            }
            const response = await axiosInstance.put(`brand/updateBrand/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/brand");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mx-4 mt-5">
                    <h3 className="page-title">Add Brand </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="subTitle"
                                                name="subTitle"
                                                placeholder="Enter sub title"
                                                value={data.subTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.subTitle && <p className="text-danger">{errors.subTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Slug Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="slugname"
                                                name="slugname"
                                                placeholder="Enter slug name"
                                                value={data.slugname}
                                                onChange={handleChange}
                                            />
                                            {errors.slugname && <p className="text-danger">{errors.slugname}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.metaTitle && <p className="text-danger">{errors.metaTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Keyword
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                            {errors.metaKeyword && <p className="text-danger">{errors.metaKeyword}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                            {errors.metaDescription && <p className="text-danger">{errors.metaDescription}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Box Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="box_image"
                                                name="box_image"
                                                placeholder="Enter brand img"
                                                onChange={handleChange}
                                            />
                                            {errors.box_image && <p className="text-danger">{errors.box_image}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Cover Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="cover_image"
                                                name="cover_image"
                                                placeholder="Enter menu img"
                                                onChange={handleChange}
                                            />
                                            {errors.cover_image && <p className="text-danger">{errors.cover_image}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Logo Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="logo_image"
                                                name="logo_image"
                                                placeholder="Enter logo img"
                                                onChange={handleChange}
                                            />
                                            {errors.logo_image && <p className="text-danger">{errors.logo_image}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Home Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="home_image"
                                                name="home_image"
                                                placeholder="Enter home img"
                                                onChange={handleChange}
                                            />
                                            {errors.home_image && <p className="text-danger">{errors.home_image}</p>}
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Show In Home
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInHome'
                                                    id="showInHome"
                                                    checked={data.showInHome}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInHome">{data.showInHome === true ? "Active" : "Deactive"}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInBrandFamily"
                                            className="col-sm-3 "
                                        >Show In Brand Family
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInBrandFamily'
                                                    id="showInBrandFamily"
                                                    checked={data.showInBrandFamily}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInBrandFamily">{data.showInHome === true ? "Active" : "Deactive"}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/brand"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.box_image && <div className="border w-100 text-center">
                                <h6 className="mt-3">Box Image Preview</h6>
                                <br />
                                {data.box_image instanceof File ? (
                                    <img src={URL.createObjectURL(data.box_image)} alt="display_name" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(data.box_image)} alt="display_name mb-3" className="w-50 mb-3" />
                                )}
                            </div>}

                            {data.cover_image && <div className="border w-100 h-25 text-center">
                                <h6 className="mt-3">Cover Image Preview</h6>
                                <br />
                                {data.cover_image instanceof File ? (
                                    <img src={URL.createObjectURL(data.cover_image)} alt="cover_image" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(data.cover_image)} alt="cover_image" className="w-50 mb-3" />
                                )}
                            </div>}

                            {data.logo_image && <div className="border w-100 h-25 text-center">
                                <h6 className="mt-3">Logo Image Preview</h6>
                                <br />
                                {data.logo_image instanceof File ? (
                                    <img src={URL.createObjectURL(data.logo_image)} alt="logo_image" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(data.logo_image)} alt="logo_image mb-3" className="w-50" />
                                )}
                            </div>}

                            {data.home_image && <div className="border w-100 h-25 text-center">
                                <h6 className="mt-3">Home Image Preview</h6>
                                <br />
                                {data.home_image instanceof File ? (
                                    <img src={URL.createObjectURL(data.home_image)} alt="home_image" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(data.home_image)} alt="home_image mb-3" className="w-50" />
                                )}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBrand;