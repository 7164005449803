import React, { useState, useEffect, useMemo } from "react";
import JoditEditor from "jodit-react";

const CodeEditor = ({ placeholder, value, onChange }) => {
  const [content, setContent] = useState(value || '');
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "Start typing...",
      uploader: {
        insertImageAsBase64URI: true,
      },
      buttons:
        "source,bold,italic,underline,strikethrough,|,ul,ol,|,font,fontsize,|,image,table,link,|,align,undo,redo,|,eraser,brush,paragraph,indent,|,selectall,cut,copy,paste,|,hr,symbol,|,left,center,right,|,superscript,subscript,|,removeformat,formatBlock,|,about",
    }),
    [placeholder]
  );

  const handleEditorChange = (newContent) => {
    setContent(newContent);
    onChange(newContent);
  };

  useEffect(() => {
    setContent(value || '');
  }, [value]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <JoditEditor
        value={content}
        config={config}
        tabIndex={1}
        onBlur={(newContent) => setContent(newContent)}
        onChange={handleEditorChange}
      />
    </div>
  );
};

export default CodeEditor;
