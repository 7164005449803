import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'


const AddSocialMedia = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state
    const [data, setData] = useState({
        icon: editData?.icon || '',
        url: editData?.url || '',
        name: editData?.name || '',
        isActive: editData?.isActive || false,
        showInHeader: editData?.showInHeader || false,
        showInFooter: editData?.showInFooter || false
    });

    const [errors, setErrors] = useState({
        icon: '',
        name: '',
        url: '',
    });


    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "isActive" || name === "showInHeader" || name === "showInFooter") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.icon.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                icon: 'Icon is required'
            }));
            hasErrors = true;
        }
        if (!data.name) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required'
            }));
            hasErrors = true;
        }
        if (!data.url) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                url: 'Url is required'
            }));
            hasErrors = true;
        }
        if (hasErrors) {
            return;
        }

        try {
            const response = await axiosInstance.post(`socialmedia/createSocialMedia`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/social-media");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`socialmedia/updateSocialMedia/${editData?._id}`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/social-media");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add Menu </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Icon
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="icon"
                                                name="icon"
                                                placeholder="Enter icon"
                                                value={data.icon}
                                                onChange={handleChange}
                                            />
                                            {errors.icon && <p className="text-danger">{errors.icon}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Url
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="url"
                                                name="url"
                                                placeholder="Enter  url"
                                                value={data.url}
                                                onChange={handleChange}
                                            />
                                            {errors.url && <p className="text-danger">{errors.url}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        > Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label " htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInHeader"
                                            className="col-sm-3 "
                                        > Show In Header
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInHeader'
                                                    id="showInHeader"
                                                    checked={data.showInHeader}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInHeader">{data.showInHeader === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInFooter"
                                            className="col-sm-3 "
                                        > Show In Footer
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInFooter'
                                                    id="showInFooter"
                                                    checked={data.showInFooter}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="showInFooter">{data.showInFooter === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/social-media"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSocialMedia
