import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../Layout/Loader'


const SocialMedia = () => {
    const [loading, setLoading] = useState(true);
    const [socialMedia, setSocialMedia] = useState([]);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigator = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`socialMedia/getSocialMedia`);
            const { data } = response.data;
            setSocialMedia(data);
            setLoading(false)
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div><Loader /></div>
    }

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(`socialmedia/deleteSocialMedia/${deleteItemId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };
    const handleStatusToggle = async (id, isActive) => {
        try {
            const response = await axiosInstance.put(`/socialmedia/updateSocialMediaStatus/${id}`, {
                isActive: isActive
            });
            if (response.status === 200) {
                toast.success(response.data?.message);
                fetchData();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title"> Social Media </h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/add-social-media">
                                    <button type="button" className="btn btn-inverse-info btn-fw"><i className="icon-plus mx-2 mt-2"></i>Add Social Media</button></Link>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive border rounded p-1">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>

                                                <th className="font-weight-bold">Name</th>
                                                <th className="font-weight-bold">Icon</th>
                                                <th className="font-weight-bold">IsActive</th>
                                                <th className="font-weight-bold">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {socialMedia.length > 0 ?
                                                socialMedia.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.name} </td>
                                                            <td>{item.icon}</td>
                                                            <td>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name='isActive'
                                                                        id={`isActiveSwitch-${index}`}
                                                                        checked={item.isActive}
                                                                        onChange={() => handleStatusToggle(item._id, !item.isActive)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`isActiveSwitch-${index}`}></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="template-demo">
                                                                    <button type="button" className="btn btn-outline-primary btn-icon-text btn-sm w-40" onClick={() => { navigator('/add-social-media', { state: item }) }}><i className="icon-pencil btn-icon-append mr-1"></i> Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-outline-danger btn-icon-text btn-sm w-35" onClick={() => handleDelete(item._id)}><i className="icon-trash btn-icon-append"></i> Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : (
                                                    <tr>
                                                        <td colSpan="6"><h5>No data found.</h5></td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} >
                <ModalHeader >Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this social media?
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <Button
                            type="cancel"
                            className="btn btn-success btn-fw"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="">
                        <Button
                            type="button" className="btn btn-danger btn-fw"
                            onClick={confirmDelete}
                        >
                            Delete
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SocialMedia;