import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'
import CodeEditor from './CodeEditor'

const AddBranch = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state

  const [data, setData] = useState({
    name: editData?.name || '',
    sortOrder: editData?.sortOrder || '',
    city: editData?.city || '',
    state: editData?.state || '',
    mobile_number: editData?.mobile_number || '',
    address: editData?.address || '',
    image: editData?.image || '',
    isActive: editData?.isActive || false,
    showInHome: editData?.showInHome || false,
  });
  const [errors, setErrors] = useState({
    name: '',
    sortOrder: '',
    mobile_number: '',
    city: '',
    state: '',
    image: '',
    mobileImg: '',
  });

  const handleChange = (e) => {
    const { name, files, checked, value } = e.target;
    if (name === 'image' || name === 'mobileImg') {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (name === 'isActive' || name === 'showInHome') {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Name is required'
      }));
      hasErrors = true;
    }
    if (!data.address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: 'Address is required'
      }));
      hasErrors = true;
    }
    if (!data.sortOrder.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sortOrder: 'sortOrder is required'
      }));
      hasErrors = true;
    }
    if (!data.city.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        city: 'City is required'
      }));
      hasErrors = true;
    }

    if (!data.state.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: 'State is required'
      }));
      hasErrors = true;
    }

    if (!data.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'Image is required'
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("address", data.address);
      formData.append("sortOrder", data.sortOrder);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("mobile_number", data.mobile_number);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
      formData.append("isActive", data.isActive);
      formData.append("showInHome", data.showInHome);
      const response = await axiosInstance.post(`branch/createBranch`, formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/branch");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {

      toast.error('Something went wrong. Please try again!!');
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("address", data.address);
      formData.append("sortOrder", data.sortOrder);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("mobile_number", data.mobile_number);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
      formData.append("isActive", data.isActive);
      formData.append("showInHome", data.showInHome);
      const response = await axiosInstance.put(`branch/updateBranch/${editData?._id}`, formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/branch");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {

      toast.error('Something went wrong. Please try again!!');
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-name">Add Branch </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-8  ">
            <div className="card">
              <div className="card-body">
                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="name"
                        name="name"
                        placeholder="Enter branch name"
                        value={data.name}
                        onChange={handleChange}
                      />
                      {errors.name && <p className="text-danger">{errors.name}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Sort Order
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="sortOrder"
                        name="sortOrder"
                        placeholder="Enter branch sortOrder"
                        value={data.sortOrder}
                        onChange={handleChange}
                      />
                      {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      City
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="city"
                        name="city"
                        placeholder="Enter branch city"
                        value={data.city}
                        onChange={handleChange}
                      />
                      {errors.city && <p className="text-danger">{errors.city}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      State
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="state"
                        name="state"
                        placeholder="Enter branch state"
                        value={data.state}
                        onChange={handleChange}
                      />
                      {errors.state && <p className="text-danger">{errors.state}</p>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="Enter branch contact number"
                        value={data.mobile_number}
                        onChange={handleChange}
                      />
                      {errors.mobile_number && <p className="text-danger">{errors.mobile_number}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Image
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="image"
                        name="image"
                        placeholder="Enter branch desktop img"
                        onChange={handleChange}
                      />
                      {errors.image && <p className="text-danger">{errors.image}</p>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Address
                    </label>
                    <div className="col-sm-9">
                      <CodeEditor
                        value={data?.address}
                        placeholder='Enter address ...'
                        onChange={(value) => handleChange({ target: { name: 'address', value } })}
                      />

                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="showInHome"
                      className="col-sm-3 "
                    >Show In Home
                    </label>
                    <div className="col-sm-9">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox" className="custom-control-input"
                          name='showInHome'
                          id="showInHome"
                          checked={data.showInHome}
                          onChange={(e) => handleChange(e)} />
                        <label className="custom-control-label " htmlFor="showInHome">{data.showInHome === true ? "Active" : "Deactive"}</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 "
                    >Is Active
                    </label>
                    <div className="col-sm-9">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox" className="custom-control-input"
                          name='isActive'
                          id="isActiveSwitch"
                          checked={data.isActive}
                          onChange={(e) => handleChange(e)} />
                        <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>

                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label"></label>
                        <div className="col-sm-9">

                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='text-center'>
                    <Link to="/branch"><button className="btn btn-secondary mr-2">
                      Back </button></Link>
                    <button type="submit" className="btn btn-success">
                      {editData ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 stretch-card">
            <div className="card">
              {data.image &&
                <div className="border w-100 h-80 text-center mb-4">
                  <h6 className="mt-3">Image Preview</h6>
                  <br />
                  {data.image instanceof File ? (
                    <img src={URL.createObjectURL(data.image)} alt="desktop_preview" className="w-50 mb-3" />
                  ) : (
                    <img src={ImageLink(data.image)} alt="desktop_preview" className="w-50 mb-3" />
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AddBranch;