import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const AddFuture = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state
    const [data, setData] = useState({
        title: editData?.title || '',
        sortOrder: editData?.sortOrder || '',
        image: editData?.image || '',
        isActive: editData?.isActive || false,
    });
    const [errors, setErrors] = useState({
        title: '',
        sortOrder: '',
        image: '',
    });

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'image') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === "isActive") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.title.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }

        if (!data.image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: 'Image is required'
            }));
            hasErrors = true;
        }
        
        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("sortOrder", data.sortOrder);
            if (data.image instanceof File) {
                formData.append("image", data.image);
            }
            formData.append("isActive", data.isActive);

            const response = await axiosInstance.post(`future/createFuture`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/future");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("sortOrder", data.sortOrder);
            if (data.image instanceof File) {
                formData.append("image", data.image);
            }
            formData.append("isActive", data.isActive);


            const response = await axiosInstance.put(`future/updateFuture/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/future");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Add Future </h3>
                </div>
                <div className="row grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Image
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="image"
                                                name="image"
                                                onChange={handleChange}
                                            />
                                            {errors.image && <p className="text-danger">{errors.image}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label " for="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label"></label>
                                                <div className="col-sm-9">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/future"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                        {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {
                                <div className="border w-100 h-75 text-center">
                                    <h6 className="mt-3">Image Preview</h6>
                                    <br />
                                    {data.image instanceof File ? (
                                        <img src={URL.createObjectURL(data.image)} alt="image_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.image)} alt="image_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFuture;