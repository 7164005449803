import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';

const Login = () => {
    const navigator = useNavigate();
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.email.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is required'
            }));
            hasErrors = true;
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Please enter a valid email address'
            }));
            hasErrors = true;
        }

        if (!data.password.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: 'Password is required'
            }));
            hasErrors = true;
        } else if (data.password.length < 6) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: 'Password must be at least 6 characters long'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const response = await axiosInstance.post(`admin/login`, data);
            localStorage.setItem("ashapuriAdminToken", response.data.authToken);
            localStorage.setItem("fullName", response.data.admin.fullName);
            localStorage.setItem("email", response.data.admin.email);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/dashboard");
            } else {
                toast.error(response.data.message);
                navigator("/");
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth">
                        <div className="row flex-grow">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left p-5">
                                    <div className="brand-logo text-center">
                                        <img src="../../images/logo.svg" alt="Logo_img" />
                                    </div>
                                    <h4 className='text-center'>Admin Login</h4>
                                    <form className="pt-3" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control form-control-sm"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                value={data.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                value={data.password}
                                                onChange={handleChange}
                                            />
                                            {errors.password && <p className="text-danger">{errors.password}</p>}
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            >
                                                SIGN IN
                                            </button>
                                        </div>
                                        <div className="my-2 text-right">
                                            <Link to="/forgot-password" className="auth-link text-black ">
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;

