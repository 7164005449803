import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'
import Select from 'react-select'


const AddProduct = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const [selectedOptions, setSelectedOptions] = useState(editData?.brand?.map((item) => ({ value: item._id, label: item.title })) || [])
    const [selectedCollection, setSelectedCollection] = useState(editData?.collection?.map((item) => ({ value: item._id, label: item.name })) || [])

    const [data, setData] = useState({
        name: editData?.name || '',
        sortOrder: editData?.sortOrder || '',
        productImg: editData?.productImg || '',
        thumbImg: editData?.thumbImg || '',
        metaTitle: editData?.metaTitle || '',
        metaKeyword: editData?.metaKeyword || '',
        metaDescription: editData?.metaDescription || '',
        isActive: editData?.isActive || false,
    });
    const [errors, setErrors] = useState({
        name: '',
        sortOrder: '',
        productImg: '',
        thumbImg: '',
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
    });

    const [brandData, setBrandData] = useState([]);
    const [collectionData, setCollectionData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`brand/getWithoutPaginationBrand`,);
            const { data } = response.data;
            setBrandData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCollectionData = async () => {
        try {
            const response = await axiosInstance.get(`collection/getWithoutPaginationCollection`);
            const { data } = response.data;
            setCollectionData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchData()
        fetchCollectionData();
    }, []);

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'productImg' || name === 'thumbImg') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (name === "isActive") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.name.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required'
            }));
            hasErrors = true;
        }

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }

        if (!data.productImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                productImg: 'Product img is required'
            }));
            hasErrors = true;
        }
        if (!data.thumbImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                thumbImg: 'Thumb img is required'
            }));
            hasErrors = true;
        }
        if (!data.metaTitle.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaTitle: 'Meta title is required'
            }));
            hasErrors = true;
        }
        if (!data.metaKeyword.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaKeyword: 'Meta keyword is required'
            }));
            hasErrors = true;
        }
        if (!data.metaDescription.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaDescription: 'Meta description is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("name", data.name);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('brand[]', selectedOptions[i].value);
            }

            for (let i = 0; i < selectedCollection?.length; i++) {
                formData.append('collection[]', selectedCollection[i].value);
            }

            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            if (data.productImg instanceof File) {
                formData.append("productImg", data.productImg);
            }
            if (data.thumbImg instanceof File) {
                formData.append("thumbImg", data.thumbImg);
            }
            formData.append("isActive", data.isActive);

            const response = await axiosInstance.post(`product/createProduct`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/product");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('brand[]', selectedOptions[i].value);
            }

            for (let i = 0; i < selectedCollection?.length; i++) {
                formData.append('collection[]', selectedCollection[i].value);
            }

            formData.append("sortOrder", data.sortOrder);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);
            if (data.productImg instanceof File) {
                formData.append("productImg", data.productImg);
            }
            if (data.thumbImg instanceof File) {
                formData.append("thumbImg", data.thumbImg);
            }
            formData.append("isActive", data.isActive);

            const response = await axiosInstance.put(`product/updateProduct/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/product");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Add Product </h3>
                </div>
                <div className="row grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Brand
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                id="brand"
                                                name="brand"
                                                placeholder="Select brand"
                                                value={selectedOptions}
                                                onChange={setSelectedOptions}
                                                options={brandData?.map((item) => ({ value: item._id, label: item.title }))}
                                                isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Collection
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                id="collection"
                                                name="collection"
                                                placeholder="Select collection"
                                                value={selectedCollection}
                                                onChange={setSelectedCollection}
                                                options={collectionData?.map((item) => ({ value: item._id, label: item.title }))}
                                                isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Product Image
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="productImg"
                                                name="productImg"
                                                placeholder="Enter product img"
                                                onChange={handleChange}
                                            />
                                            {errors.productImg && <p className="text-danger">{errors.productImg}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Thumb Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="thumbImg"
                                                name="thumbImg"
                                                placeholder="Enter thumb img"
                                                onChange={handleChange}
                                            />
                                            {errors.thumbImg && <p className="text-danger">{errors.thumbImg}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.metaTitle && <p className="text-danger">{errors.metaTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Keyword
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                            {errors.metaKeyword && <p className="text-danger">{errors.metaKeyword}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                            {errors.metaDescription && <p className="text-danger">{errors.metaDescription}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label"></label>
                                                <div className="col-sm-9">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/product"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.productImg &&
                                <div className="border w-100 h-75 text-center">
                                    <h6 className="mt-3">Product Preview</h6>
                                    <br />
                                    {data.productImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.productImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.productImg)} alt="desktop_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }
                            {data.thumbImg &&
                                <div className="border w-100 h-80 text-center mb-4">
                                    <h6 className="mt-3">Mobile Preview</h6>
                                    <br />
                                    {data.thumbImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.thumbImg)} alt="mobile_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(data.thumbImg)} alt="mobile_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddProduct;