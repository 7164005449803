import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';
import { ImageLink } from '../constants';
import Select from 'react-select';
import CodeEditor from './CodeEditor';

const AddPage = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const [selectedOptions, setSelectedOptions] = useState(editData?.contentId?.map((item) => ({ value: item._id, label: item.title })) || []);    

    const [data, setData] = useState({
        title: editData?.title || '',
        content: editData?.content || '',
        desktopImg: editData?.desktopImg || '',
        mobileImg: editData?.mobileImg || '',
        isActive: editData?.isActive || false,
    });

    const [errors, setErrors] = useState({
        title: '',
        content: '',
    });

    const [contentData, setContentData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`content/getAllContents`);
            const { data } = response.data;
            setContentData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === 'desktopImg' || name === 'mobileImg') {
            const imageData = files?.length > 0 ? files[0] : editData[name];
            setData({ ...data, [name]: imageData });
        } else if (name === "isActive") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.title.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }
        if (!data.content.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                content: 'content is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("content", data.content);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('contentId[]', selectedOptions[i].value);
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            formData.append("isActive", data.isActive);

            const response = await axiosInstance.post(`page/createPage`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/page");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("content", data.content);
            for (let i = 0; i < selectedOptions?.length; i++) {
                formData.append('contentId[]', selectedOptions[i].value);
            }
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            } else if (editData.desktopImg) {
                const blob = await fetch(editData.desktopImg).then((res) => res.blob());
                formData.append("desktopImg", blob, editData.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            formData.append("isActive", data.isActive);

            const response = await axiosInstance.put(`page/updatePage/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/page");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add Page </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Content Id
                                        </label>
                                        <div className="col-sm-9 form-group">
                                            <Select
                                                id="contentId"
                                                name="contentId"
                                                placeholder="Select content"
                                                value={selectedOptions}
                                                onChange={setSelectedOptions}
                                                options={contentData?.map((item) => ({ value: item._id, label: item.title }))}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter slider title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Content
                                        </label>
                                        <div className="col-sm-9">                                         
                                            <CodeEditor value={data.content}  style={{ height: '400px' }}   onChange={(value) => handleChange({ target: { name: 'content', value } })}  />
                                           
                                            {errors.content && !data?.content?.trim() && (
                                                <div className="text-danger">Content is required</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Desktop Img
                                        </label>

                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="desktopImg"
                                                name="desktopImg"
                                                placeholder="Enter desktop img"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Mobile Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="mobileImg"
                                                name="mobileImg"
                                                placeholder="Enter mobile img"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3"> Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/page"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {data.desktopImg &&
                                <div className="border w-100 h-75 text-center">
                                    <h6 className="mt-3">Desktop Preview</h6>
                                    <br />
                                    {data.desktopImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.desktopImg)} alt="desktop_preview" className="w-50" />
                                    ) : (
                                        <img src={ImageLink(data.desktopImg)} alt="desktop_preview" className="w-50" />
                                    )}
                                </div>
                            }
                            {data.mobileImg &&
                                <div className="border w-100 h-80 text-center mb-4">
                                    <h6 className="mt-3">Mobile Preview</h6>
                                    <br />
                                    {data.mobileImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.mobileImg)} alt="mobile_preview" className="w-50" />
                                    ) : (
                                        <img src={ImageLink(data.mobileImg)} alt="mobile_preview" className="w-50" />
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPage;
